<template>
   <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @search="search"
                 @select="select"></search-form>
    <!-- 列表 -->
    <table-list :datas="searchVal"></table-list>

  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      // 表单
      form: {
        shard: null, // 分片名（默认全部）
        ledgerType: null, // 账本类型（默认全部）
        blockHeight: '',
        hash: '',
        sendAddress: '',
        timeList: []
      },
      searchVal: {
        shard: null, // 分片名（默认全部）
        ledgerType: null, // 账本类型（默认全部）
        blockHeight: '',
        hash: '',
        sendAddress: '',
        timeList: []
      }
    }
  },
  created() {
    if (getShardName()) {
      this.form.shard = getShardName()
      this.searchVal.shard = getShardName()
    }
  },
  mounted() {},
  methods: {
    // 存储分片名
    select(value) {
      setShardName(value)
    },
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.searchVal = form
    }
  }
}
</script>
