<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="110px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       @change="handleSelect"
                       v-model="form.shard">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="账本类型">
            <el-select class="inputWid"
                       v-model="form.ledgerType"
                       @keyup.enter.native="confirm">
              <el-option v-for="item in ledgerList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="区块高度">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.blockHeight"
                      type="number"
                      clearable
                      placeholder="请输入区块高度"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="账本哈希">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.hash"
                      clearable
                      placeholder="请输入账本哈希"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="创建地址">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.sendAddress"
                      clearable
                      placeholder="请输入创建地址"></el-input>
          </el-form-item>

        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="创建时间范围">
            <el-date-picker class="inputWid"
                            v-model="form.timeList"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      ledgerList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getAliases()
    this.getLedgerList()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 获取账本类型数组
    async getLedgerList() {
      let { data } = await this.$api.chainQL.listLedgerType()
      // console.log(data)
      let ledgerList = data.listLedgerType
      let obj = {
        label: '全部',
        value: null
      }
      ledgerList.splice(0, 0, obj)
      this.ledgerList = ledgerList
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    rest() {
      // 这里重置默认选中第一个选项
      // this.form.shard = this.options[0].value
      this.form.shard = null
      this.form.ledgerType = null
      this.form.blockHeight = ''
      this.form.hash = ''
      this.form.sendAddress = ''
      this.form.timeList = []
      this.$emit('select', null)
      this.confirm()
    },
    confirm() {
      let _form = {
        shard: this.form.shard,
        ledgerType: this.form.ledgerType,
        blockHeight: this.form.blockHeight ? this.trim(this.form.blockHeight) : '',
        hash: this.form.hash ? this.trim(this.form.hash) : '',
        sendAddress: this.form.sendAddress
          ? this.trim(this.form.sendAddress)
          : '',
        timeList: this.form.timeList || []
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
