var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"columns":_vm.columns,"data":_vm.ledgerList,"fit":"","stripe":"","tableOption":{size: 'mini'},"loading":_vm.loading,"pageData":_vm.pageData,"headData":_vm.headData},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"blockHeight",fn:function(ref){
var row = ref.row;
return [(row.blockHeight)?_c('span',[_vm._v(_vm._s(_vm.toThousands(row.blockHeight)))]):_c('span',[_vm._v("无")])]}},{key:"hashAlgorithm",fn:function(ref){
var row = ref.row;
return [(row.hashAlgorithm===201)?_c('span',[_vm._v("sha3")]):(row.hashAlgorithm===202)?_c('span',[_vm._v("sm3")]):_vm._e()]}},{key:"hash",fn:function(ref){
var row = ref.row;
return [(row.hash)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.hash,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy copy-icon",on:{"click":function($event){return _vm.copy($event, row.hash)}}})],1):(!row.hash)?_c('span',[_vm._v("无")]):_vm._e()]}},{key:"sendAlgorithm",fn:function(ref){
var row = ref.row;
return [(row.sendAlgorithm===101)?_c('span',[_vm._v("rsa")]):(row.sendAlgorithm===102)?_c('span',[_vm._v("sm2")]):_vm._e()]}},{key:"sendAddress",fn:function(ref){
var row = ref.row;
return [(row.sendAddress)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.sendAddress,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy copy-icon",on:{"click":function($event){return _vm.copy($event, row.sendAddress)}}})],1):(!row.sendAddress)?_c('span',[_vm._v("无")]):_vm._e()]}},{key:"verifyAlgorithm",fn:function(ref){
var row = ref.row;
return [(row.verifyAlgorithm===101)?_c('span',[_vm._v("rsa")]):(row.verifyAlgorithm===102)?_c('span',[_vm._v("sm2")]):_vm._e()]}},{key:"verifyAddress",fn:function(ref){
var row = ref.row;
return [(row.verifyAddress&&row.verifyAddress.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.verifyAddress,1)}}},[_vm._v("..."+_vm._s(row.verifyAddress.substr(row.verifyAddress.length-26,row.verifyAddress.length-1)))]):(!row.verifyAddress)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.verifyAddress))])]}},{key:"timestamp",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"promiseCode",fn:function(ref){
var row = ref.row;
return [(row.promiseCode===101)?_c('span',[_vm._v("PoL")]):(row.promiseCode===201)?_c('span',[_vm._v("PoS")]):(row.promiseCode===301)?_c('span',[_vm._v("Paxos")]):_vm._e()]}},{key:"sendSign",fn:function(ref){
var row = ref.row;
return [(row.sendSign&&row.sendSign.length>20)?_c('span',[_vm._v("..."+_vm._s(row.sendSign.substr(row.sendSign.length-26,row.sendSign.length-1)))]):(!row.sendSign)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.sendSign))])]}},{key:"verifySign",fn:function(ref){
var row = ref.row;
return [(row.verifySign&&row.verifySign.length>20)?_c('span',[_vm._v("..."+_vm._s(row.verifySign.substr(row.verifySign.length-26,row.verifySign.length-1)))]):(!row.verifySign)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.verifySign))])]}},{key:"result",fn:function(ref){
var row = ref.row;
return [(row.result)?_c('span',[_vm._v("成功")]):(!row.result)?_c('span',[_vm._v("失败")]):_vm._e()]}},{key:"receiveAddress",fn:function(ref){
var row = ref.row;
return [(row.receiveAddress&&row.receiveAddress.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.receiveAddress,1)}}},[_vm._v("..."+_vm._s(row.receiveAddress.substr(row.receiveAddress.length-26,row.receiveAddress.length-1)))]):(!row.receiveAddress)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.receiveAddress))])]}},{key:"token",fn:function(ref){
var row = ref.row;
return [(row.token&&row.token.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.token,2)}}},[_vm._v("..."+_vm._s(row.token.substr(row.token.length-26,row.token.length-1)))]):(!row.token)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.token))])]}},{key:"owner",fn:function(ref){
var row = ref.row;
return [(row.owner&&row.owner.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.owner,2)}}},[_vm._v("..."+_vm._s(row.owner.substr(row.owner.length-26,row.owner.length-1)))]):(!row.owner)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.owner))])]}},{key:"reCall",fn:function(ref){
var row = ref.row;
return [(row.reCall)?_c('span',[_vm._v("是")]):(!row.reCall)?_c('span',[_vm._v("否")]):_vm._e()]}},{key:"redemptionTimestamp",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.redemptionTimestamp, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"reHash",fn:function(ref){
var row = ref.row;
return [(row.reHash&&row.reHash.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.reHash,2)}}},[_vm._v("..."+_vm._s(row.reHash.substr(row.reHash.length-26,row.reHash.length-1)))]):(!row.reHash)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.reHash))])]}},{key:"depositHash",fn:function(ref){
var row = ref.row;
return [(row.depositHash&&row.depositHash.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.depositHash,2)}}},[_vm._v("..."+_vm._s(row.depositHash.substr(row.depositHash.length-26,row.depositHash.length-1)))]):(!row.depositHash)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.depositHash))])]}},{key:"parentHash",fn:function(ref){
var row = ref.row;
return [(row.parentHash&&row.parentHash.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.parentHash,2)}}},[_vm._v("..."+_vm._s(row.parentHash.substr(row.parentHash.length-26,row.parentHash.length-1)))]):(!row.parentHash)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.parentHash))])]}},{key:"dataSources",fn:function(ref){
var row = ref.row;
return [(row.dataSources)?_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.dataSources))]):_c('span',[_vm._v("无")])]}},{key:"txHash",fn:function(ref){
var row = ref.row;
return [(row.txHash&&row.txHash.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.txHash,2)}}},[_vm._v("..."+_vm._s(row.txHash.substr(row.txHash.length-26,row.txHash.length-1)))]):(!row.txHash)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.txHash))])]}},{key:"daHash",fn:function(ref){
var row = ref.row;
return [(row.daHash&&row.daHash.length>20)?_c('span',{staticClass:"table-row",on:{"click":function($event){return _vm.handleOpen(row.daHash,2)}}},[_vm._v("..."+_vm._s(row.daHash.substr(row.daHash.length-26,row.daHash.length-1)))]):(!row.daHash)?_c('span',[_vm._v("无")]):_c('span',{staticClass:"shenglue"},[_vm._v(_vm._s(row.daHash))])]}},{key:"statusKey",fn:function(ref){
var row = ref.row;
return [(row.statusKey)?_c('span',[_vm._v("..."+_vm._s(row.statusKey.substr(row.statusKey.length-26,row.statusKey.length-1)))]):_c('span',[_vm._v("无")])]}},{key:"json",fn:function(ref){
var row = ref.row;
return [(row.json)?_c('span',{staticClass:"table-row shenglue",on:{"click":function($event){return _vm.handleOpen(row.json,3)}}},[_vm._v(_vm._s(row.json))]):_c('span',[_vm._v("无")])]}},{key:"message",fn:function(ref){
var row = ref.row;
return [(row.message)?_c('span',{staticClass:"table-row shenglue",on:{"click":function($event){return _vm.handleOpen(row.message,4)}}},[_vm._v(_vm._s(row.message))]):_c('span',[_vm._v("无")])]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [(row.remark)?_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.remark,"divClass":'shenglue-1',"isSubStr":false}}):_c('div',[_vm._v("无")])]}},{key:"contract",fn:function(ref){
var row = ref.row;
return [(row.contract)?_c('el-button',{attrs:{"type":"text","icon":"ym-icon-liulan"},on:{"click":function($event){return _vm.handleContract(row.contract)}}},[_vm._v(" 查看")]):_c('span',[_vm._v("无")])]}}])}),_c('el-dialog',{attrs:{"title":_vm.title,"width":"55%","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.info)?_c('pre',{domProps:{"innerHTML":_vm._s(_vm.info)}}):_c('div',[_vm._v("暂无数据")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }